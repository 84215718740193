/**
 * TOOLS.MIXIN.CLEARFIX
 *
 * @description : Force an element to self-clear floated children
 *
 */

@mixin clearfix {
	&::after {
	  content: "";
	  display: table;
	  clear: both;
	}
}

@mixin background-opacity($color, $opacity: 0.3) {
	background: $color; /* The Fallback */
	background: rgba($color, $opacity);
}