/**
 * ELEMENTS.IMAGES
 *
 * @description : Basic image styles
 *
 */

/**
 *  Set images for responsive sizing
 */
img,
svg {
    display: block;
    max-width: 100%;
    height: auto;
}
// Used as a backdrop for media before loaded, or if absent.
$placeholder-color-light: rgba(#000, 0.06);
$placeholder-color-dark: rgba(#000, 0.06);

.c-media {
    background: $placeholder-color-light;
    display: block;
    position: relative;

    .swiper-container & {
        user-select: none;
    }
}

.c-media--placeholder-dark {
    background: $placeholder-color-dark;
    color: #fff;
}

.c-media--can-have-transparency {
    background-color: transparent;
}

.c-media--zoom-on-hover {
    overflow: hidden;
}

.c-media--full-width {
    height: auto;
    width: 100%;
}

.c-media--full-height {
    height: 100%;
    width: auto;
}

.c-media--full-width-and-height {
    height: 100%;
    width: 100%;
}

.c-media__link {
    display: block;
    height: 100%;
    width: 100%;
}

.c-media__ratio-sizer-image {
    display: block;
    height: auto;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    z-index: -1;

    .c-media--full-width & {
        height: auto;
        width: 100%;
    }

    .c-media--full-height & {
        height: 100%;
        width: auto;
    }

    .c-media--full-width-and-height & {
        height: 100%;
        width: 100%;
    }
}

.c-media__ratio-sizer-image--mobile {
    display: none;

    @media (max-width: ($md - 1px)) {
        display: block;

        & ~ .c-media__ratio-sizer-image {
            display: none;
        }
    }
}

.c-media__ratio-sizer-image--tablet {
    display: none;

    @media (max-width: ($lg - 1px)) {
        display: block;

        & ~ .c-media__ratio-sizer-image {
            display: none;
        }
    }
}

.c-media__iframe-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.c-media__iframe-wrap {
    bottom: -100vw;
    box-sizing: border-box;
    height: 100%;
    left: -100vw;
    margin: auto;
    max-height: 100%;
    padding-top: 56.25%; // This style should be overridden inline.
    position: absolute;
    right: -100vw;
    top: -100vw;
}

.c-media__iframe-wrap--background {
    pointer-events: none;
}

.c-media__iframe-wrap--click-to-play {
    cursor: pointer;

    &::before {
        background: rgba(#000, 0.25);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.2s;
        z-index: 1;
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Ccircle cx='100' cy='100' r='100' fill='%23fff'/%3E%3Cpath fill='%23e4b711' d='m126.61 100-44 30.61V69.39l44 30.61z'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: rem(100px) rem(100px);
        color: #fff;
        content: '';
        display: block;
        height: rem(100px);
        left: 50%;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.2s;
        width: rem(100px);
        z-index: 2;
    }

    &.is-playing {
        &::before,
        &::after {
            opacity: 0;
        }
    }
}

.c-media__image,
.c-media__iframe {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;

    .c-media--zoom-on-hover .c-media__link &,
    a .c-media--zoom-on-hover & {
        transform: scale(1);
        transition: opacity 0.3s, transform 1s ease-in-out;
    }

    .c-media--zoom-on-hover .c-media__link:hover &,
    a:hover .c-media--zoom-on-hover & {
        transform: scale(1.05);
    }
}

.c-media__image {

    .c-media--svg & {
        object-fit: contain;
    }

    .c-media--no-dimensions & {
        bottom: auto;
        left: auto;
        position: relative;
        right: 0;
        top: auto;
    }

    .c-media--no-dimensions.c-media--full-width & {
        height: auto;
        width: 100%;
    }

    .c-media--no-dimensions.c-media--full-height & {
        height: 100%;
        width: auto;
    }

    .c-media--no-dimensions.c-media--full-width-and-height & {
        height: 100%;
        width: 100%;
    }
}