/**
 * SETTINGS.BASE
 *
 * @description : Key application variables and settings
 *
 */

$base-font-size: 21px;
$base-line-height: 1.5; // 24px

// Spacing unit for calculating consistent margins/padding etc.
// Uses 8px grid system by default https://medium.com/built-to-adapt/intro-to-the-8-point-grid-system-d2573cde8632
// Apply units with the spacer() function,
//  e.g. margin-top: spacer()
//  or multiples of the spacer: padding-bottom: spacer(4)

$spacer: 0.5rem; // 8px;
