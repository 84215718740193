/**
 * ELEMENTS.FORMS
 *
 * @description : Core form styles
 *
 */

$form_el_v_padding: 0.6em;
$form_el_h_padding: 0.9em;

form {
  width: 100%;
}

label {
  display: inline-block;
  &.aligned {
    padding: $form_el_v_padding 0; // keep labels horizontally aligned with fields
    border-top: 1px solid transparent; // adjusts for input border to keep text baselines aligned
  }
}

%text_input {
  font-family: "Aller", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
  border: none;
  border-radius: 5px; //  Reset User Agent styles for iOS
  display: inline-block;
  padding: $form_el_v_padding $form_el_h_padding;
  width: 100%;
  font-size: 1em;
  box-shadow: 0 2px 10px 0 $black-15;
  border: 1px solid $field-border;
  font-weight: 100;
  color: $field-color;

  &:focus {
    border: 1px solid $field-border-focus;
    outline: none;
  }
  &::placeholder {
    color: $field-placeholder-color;

    @include breakpoint($md) {
      //font-style: italic;
    }
  }

  @include breakpoint($sm){
    width:100%;
  }

  @include breakpoint($md) {
    font-size: 1em;
  }

  &.c-large-input__input {  
    width:calc(100% - 42px);
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="search"],
select,
input[type="url"] {
  @extend %text_input;
}

input[type="radio"] {
}

input[type="checkbox"] {
}

textarea {
  @extend %text_input;
  min-height: 10em;
  resize: vertical;
}

button {
  // Remove user agent styles
  appearance: none;
  border-radius: 0; //  Reset for iOS
  border: 0;
  display: inline-block;
  padding: 0;
}
select {
  font-size: 21px;
  color:$checkbox-title-color;
  -webkit-appearance: none;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
  border: 1px solid $dropdown-border;
  padding:14px;
}
input[type="submit"] {
  appearance: none;
  border-radius: 0; //  Reset for iOS
  border: 0;
  display: inline-block;
  font-size: 0.9em;
  padding: 1em 3.5em;
  border-radius: 5px;
  background-color: $environment-green;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  transition: background-color 0.25s ease, box-shadow 0.25s ease;

  &:hover {
    background-color: $financial-green;
    box-shadow: none;
  }
}
.field-validation-error {
  font-weight: 100;
  font-size: .725em;
  color:$field-error-color;
}