@use "sass:math";
/**
 * TOOLS.FUNCTIONS
 *
 * @description : Common functions
 *
 */


/**
 * Strip Unit
 *  @desc       : Strips the unit from a value to we can use it for calculations
 *  @source     : https://css-tricks.com/snippets/sass/strip-unit-function/
 *  @param      : $num - Number to remove unit from
 *  @return     : Unitless value
 */

 @function strip-unit($num) {
    @return math.div($num , ($num * 0 + 1));
 }


/**
 *  REM Calc
 *  @desc      : Converts px values to REMs
 *  @param     : $val - The value to convert (usually passed in px but unit may be omitted)
 *  @param     : $base – Optional parameter, passed if you'd like to calculate with a different base font size
 *  @param     : $base-font-size – The default font size (defined in _settings.base.scss)
 *  @return    : $rems - rem-converted value
 */

 @function rem($val, $base:null){

     @if $base == null {
         $base : $base-font-size;
     }

     @if unit($base) == '%' {
         $base : ($base / 100%) * 16px;
     }

    $rems: calc( strip-unit($val) / strip-unit($base) ) + rem;

     @return $rems;

 }


/**
 *  Decimal-Round
 *  @desc      : Rounds a floating point number to the nearest whole number
 *  @param     : $number - The number to be rounded. must be unitless.
 */

@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode == round {
        @return calc(round($number * $n) / $n);
    } @else if $mode == ceil {
        @return calc(ceil($number * $n) / $n);
    } @else if $mode == floor {
        @return calc(floor($number * $n) / $n);
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}


/**
 *  Decimal-Floor
 *  @desc      : Rounds a floating point number down to the nearest whole number
 */

@function decimal-floor ($number, $digits: 0) {
    @return decimal-round($number, $digits, floor);
}


/**
 * Spacer
 * @desc     : Returns a simple multipled value, converted to rem
 */

@function spacer ($m: 1, $spacer: $spacer) {
    @return rem( $m * $spacer );
}
