/**
 * TOOLS.MIXIN.HEADING-SCALE
 *
 * @description : Generates heading sizes, their line heights and bottom margins from the base font size and a multiplier
 *                Reuseable classes are also generated
 *
 */

@mixin headingScale($base-font-size, $scale-multiplier, $classes-only: false) {
    $classes: 'peta', 'tera', 'giga', 'mega', 'kilo', 'hecto';

    $n: 1; // A dynamic counter for the loop

    // Loop through 6 heading levels and output styles
    @for $h from 6 through 1 {
        // Calculate the font size for this iteration
        $current-size: ceil($base-font-size * $n);

        // Main heading styles
        @if ($classes-only == true) {
            .h-#{nth($classes, $h)} {
                font-size: ceil($base-font-size * $n);
                line-height: ceil($base-font-size + ($base-font-size * $n / 2 * $scale-multiplier));
                margin-bottom: ceil($base-font-size * $n / 2);
            }
        } @else {
            h#{$h},
            .h-#{nth($classes, $h)} {
                font-size: ceil($base-font-size * $n);
                line-height: ceil($base-font-size + ($base-font-size * $n / 2 * $scale-multiplier));
                margin-bottom: ceil($base-font-size * $n / 2);
            }
        }

        $n: $n * $scale-multiplier; // Increase our multiple for the next iteration
    }
}
