/**
 * ELEMENTS.DISABLED
 *
 * @description : Default styles for disabled elements
 *
 */

// The absence of the disabled attribute is the only valid syntax for "false",
// i.e. <input type="text" disabled="false" /> is not valid
.disabled,
*:disabled,
[disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
}
