/**
 * MASTER.SCSS
 *
 * @Project     : Project Name
 * @Author      : AuthorName [author.name@nvinteractive.com]
 *
 */

// Set the viewport to resize responsively (similar to viewport meta tag)
@viewport {
    width: device-width;
    zoom: 1;
}

//  0. PLUGINS
@import '../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss';

/**
 * 1. SETTINGS
 */
@import '1.settings/settings.animations';
@import '1.settings/settings.base';
@import '1.settings/settings.breakpoints';
@import '1.settings/settings.colors';
@import '1.settings/settings.grid';

/**
 *  2. TOOLS 
 */
@import '2.tools/tools.functions.scss';
@import '2.tools/tools.mixin.heading-scale.scss';
@import '2.tools/tools.mixin.position.scss';
@import '2.tools/tools.mixin.clearfix.scss';
@import '2.tools/tools.mixin.baseline_grid.scss'; // Adds a handy baseline grid for setting type.

/**
 * 3. GENERIC
 */
@import '3.generic/generic.reset';
@import '3.generic/generic.boxsizing';

/**
 *  4. ELEMENTS
 *  Basic styles for raw HTML elements
 *  No classes here
 */
@import '4.elements/elements.base';
@import '4.elements/elements.disabled';
@import '4.elements/elements.forms';
@import '4.elements/elements.images';
@import '4.elements/elements.type';

/**
 *  5. OBJECTS
 *  These are repeating visual/structural patterns with no 'design' added
 *  Must be built with classes only
 */
@import '5.objects/objects.grid';
@import '5.objects/objects.layout';
