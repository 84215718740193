/**
 * TOOLS.MIXIN.POSITION
 *
 * @description :  Sets top, right, bottom and left values for an element
 * @note: Pass 'null' as an argument to ensure that value isn't set
 *
 */

@mixin position($top:'null', $right:'null', $bottom:'null', $left:'null'){
    @if($top != 'null') {
        top: $top;
    }
    @if($right != 'null') {
        right: $right;
    }
    @if($bottom != 'null') {
        bottom: $bottom;
    }
    @if($left != 'null') {
        left: $left;
    }
}
