/**
 * ELEMENTS.TYPE
 *
 * @description : Basic typographical styles
 *
 */

$main-font: 'Aller', 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
$secondary-font: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
$component-label-font: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;

body {
    color: $text-color;
    font-family: $main-font;
    font-size: $base-font-size;
    line-height: $base-line-height;
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    font-family: $main-font;
    margin: 0;
}

/**
 * Optional: Generate headings using a scale based on the body font size.
 * Also matching classes using SI prefixes are also generated. Done at the element level to keep specificity low.
 * See https://www.modularscale.com for common scale multipliers
 * and https://csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/ for more info on using classes for headings
 *
 * Use this function to generate heading metrics, or declare them manually below
 * @include headingScale($base-font-size, 1.25);
 */

h1,
.h-peta {
    font-size: 50px;
}

h2,
.h-tera {
    font-size: 35px;
}
h3,
.h-giga {
    font-size: 25px;
    margin-bottom: 12px;
}

h4,
.h-mega {
    font-size:17px;
    text-transform: uppercase;
    font-weight: 600;
}

h5,
.h-kilo {
    // styles ...
}

h6,
.h-hecto {
    // styles ...
}

p {
    font-size: 0.875em;
    margin: 0 0 ($base-line-height + em) 0;
    font-weight: 300;
    
    @include breakpoint($md){
        font-size: $base-font-size;
    }
}

small {
    font-size: 16px;
}

ul {
}

ol {
}

a {
    text-decoration-skip-ink: auto; // Stop character descenders colliding with link underlines.
    text-decoration: underline;
    color: $environment-green;
}

strong {
    font-weight: 500;
}
