/**
 * OBJECTS.LAYOUT
 *
 * @description : Core layout objects used in addition to the grid
 *
 */

/*  e.g.

    .o-page {
        padding-bottom: rem(40);
    }

*/

.o-page-header {
    margin:32px 15px 42px 15px;
    
    @include breakpoint($lg) {
        margin:32px 0px 42px 0px;
    }
}

.o-main-layout__video-modal {
    bottom: 0;
    left: 0;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 400;
}