/**
 * SETTINGS.GRID
 *
 * @description : Settings used in grid calculations
 *
 */

$bodyMax:        83.5rem; //1336
$bodyMaxSlim:    61.25rem;
$gridCols:       12;
$boxSizing:      border-box;
$includeIndent:  true;  // Set to true to create indent classes

// Default Gutters
$gutterValue:    14 !default;  // based on Piet & Diego's work 2.333% for 12 columns, 1.166% for 24 columns (12 * 2.333 = ~28 and 24 * 1.166 = ~28 too)
$gutter:         percentage(calc(calc($gutterValue / $gridCols) / 100));
$negativeGutter: percentage(calc(calc($gutterValue / $gridCols) / 100 * -1));
$noMargin: 0 percentage(calc(calc($gutterValue / $gridCols) / 100 * -1));
