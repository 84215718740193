/**
 * ELEMENTS.BASE
 *
 * @description : Base element styles
 *
 */

html {
    height: 100%;
    background: $primary-bg;
    scroll-behavior: smooth;
}

body {
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // displays a baseline grid as a background for setting vertical rhythm
    &.dev_type {
        @include baseline_grid();
    }

    &.section-sport-and-rec {
        background: $sport-bg;
    }
}

figure {
    margin: 0;
}

hr {
    border: 0;
    border-top: 1px solid rgba($gamma, 0.2);
    margin: 3em 0;
}
