/**
 * SETTINGS.COLORS
 *
 * @description : Color variable definitions
 *
 */

/**
 * Main Colours
 */
$alpha: #000000; // Color name
$beta: #444444; // Color name
$gamma: #000000; // Color name
$delta: #000000; // Color name
//$kappa       : #000000;  // Color name
//$tau         : #000000;  // Color name
//$psi         : #000000;  // Color name
//$omega       : #000000;  // Color name

$heading-color: $alpha;
$text-color: $beta;

$white: #fff;
$off-white: #E7EEF2;
$silver: #D5E0E4;
$light-grey: #D8D8D8;
$mid-grey:#6e6e6e;
$concrete :#737373;
$darker-grey: #595959;
$dark-grey: #505E67;
$dark-blue :#00283F;
$primary-blue: #00395b;
$med-blue: #1771A6;
$tasman-mako: #00355F;
$marine-blue: #083F60;
$border-blue: #CDD7DF;
$environment-green: #40860E;
$lime-scooter: #8CC63F;
$regular-green:#40860e;
$old-green: #50A712;
$new-green: #3d833e;
$sport-blue: #4485c6;
$marine: #135E76;
$local-democracy-cyan: #00ADEE;
$community-teal: #009F90;
$financial-green: #006937;
$infrastructure-orange: #F6931D;
$mustard: #D99318;
$regulatory-red: #E21F26;
$regulatory-warm-red: #EF533F;
$regular-red:#f9423a;
$economy-purple: #8C275B;
$library-blue: #0170A4;
$black: #000;
$black-70: rgba(0,0,0,0.7);
$black-20: rgba(0,0,0,0.2);
$black-15: rgba(0,0,0,0.15);
$sky-blue: #4A90E2;
$offwhite-translucent :rgba(140,198,63,0.07);
$offwhite: #EDF1E8;
$border-grey: rgba(0,57,91,0.2);
$primary-bg: $white;
$secondary-bg: #FAFAF9;
$sport-bg: #F9FBFE;
$scrub-blue: #0089CB;
$grey-60: #757575;
$grey-50: #717272;

/**
 * LISTING
 */
$listing-border: $primary-blue;
$listing-bg: $white;
$listing-text-colour: $primary-blue;
$listing-link-colour: $environment-green;

/**
 * SPINNER
 */
$spinner-colour:$lime-scooter;

/**
 * MODAL
 */
$modal-color:$environment-green;
$modal-bg-mask:$black-70;

/**
 * FIELDS
 */
$field-color:$mid-grey;
$field-placeholder-color:$concrete;
$field-select-arrow:$black;
$field-border-focus:$mid-grey;
$field-border:$silver;
$field-label-color:$primary-blue;
$field-error-color:$regulatory-red;

/**
 * PINS
 */
$pin-text-color: $primary-blue;
$pin-caption-color: $mid-grey;

/**
 * ALERTS
 */
$alert-text-color:$regulatory-warm-red;


/**
 * ACCORDION
 */
$accordion-title-color: $primary-blue;
$accordion-border: $light-grey;
$accordion-box-shadow: $light-grey;
$accordion-btn-active: $environment-green;


/**
 * TIMETABLE
 */
$timetable-color:$marine-blue;
$timetable-bg: $white;
$timetable-active-bg: rgba(222,237,206,0.3);
$timetable-time-color:$mid-grey;
$timetable-border-color:$black-20;
$timetable-active-color:$environment-green;
$timetable-header-bg: $scrub-blue;


/**
 * SECTION SPORT
 */
$section-sport-color:$white;

/**
 * RADIO
 */
$radio-border:$mid-grey;
$radio-bg:$white;
$radio-checked-bg:$sky-blue;
$radio-title-color:$primary-blue;

/**
 * CHECKBOX
 */
$checkbox-border:$mid-grey;
$checkbox-bg:$white;
$checkbox-checked-bg:$sky-blue;
$checkbox-title-color:$primary-blue;

/**
 * DROPDOWN
 */
$dropdown-bg:$white;
$dropdown-border:$light-grey;
$dropdown-chevron:$black;
$dropdown-disabled:$light-grey;

/**
 * ANCHOR LINK
 */
$anchor-link-color: $mid-grey;
$anchor-link-hover-color: $environment-green;

/**
 * RELATED CONTENT
 */
$related-content-border: $border-grey;

/**
 * SIDENAV
 */
$sidenav-link-color:$primary-blue;
$sidenav-link-active-color: $lime-scooter;

/**
 * CARD
 */
$card-icon-bg: $secondary-bg;
$card-link-color: $grey-50;
$card-chevron-color: $dark-grey;
$card-related-circle: $silver;
$card-related-circle-hover: $scrub-blue;

/**
 * SHARING LINKS
 */
$sharing-links-label-colour: $darker-grey;

/**
 * BREADCRUMBS
 */
$breadcrumbs-colour: $concrete;

/**
 * BANNER
 */
$banner-blend-color: #293842;

/**
 * UX/Validation colors
 */
$notice: #2ab9ff; // Blue
$confirm: #6fd100; // Green
$alert: #ffa800; // Orange
$warning: #c60000; // Red

$main-colors: (
    "white": $white,
    "off-white": $off-white,
    "silver": $silver,
    "light-grey": $light-grey,
    "mid-grey": $mid-grey,
    "concrete": $concrete,
    "darker-grey": $darker-grey,
    "dark-grey": $dark-grey,
    "dark-blue": $dark-blue,
    "primary-blue": $primary-blue,
    "med-blue": $med-blue,
    "tasman-mako": $tasman-mako,
    "marine-blue": $marine-blue,
    "border-blue": $border-blue,
    "environment-green": $environment-green,
    "lime-scooter": $lime-scooter,
    "regular-green": $regular-green,
    "old-green": $old-green,
    "new-green": $new-green,
    "sport-blue": $sport-blue,
    "marine": $marine,
    "local-democracy-cyan": $local-democracy-cyan,
    "community-teal": $community-teal,
    "financial-green": $financial-green,
    "infrastructure-orange": $infrastructure-orange,
    "mustard": $mustard,
    "regulatory-red": $regulatory-red,
    "regulatory-warm-red": $regulatory-warm-red,
    "regular-red": $regular-red,
    "economy-purple": $economy-purple,
    "library-blue": $library-blue,
    "black": $black,
    "black-70": $black-70,
    "black-20": $black-20,
    "black-15": $black-15,
    "sky-blue": $sky-blue,
    "offwhite-translucent": $offwhite-translucent,
    "border-grey": $border-grey,
    "primary-bg": $primary-bg,
    "secondary-bg": $secondary-bg,
    "sport-bg": $sport-bg,
    "scrub-blue": $scrub-blue,
    "grey-60": $grey-60,
    "grey-50": $grey-50
);