/**
 * SETTINGS.BREAKPOINTS
 *
 * @description : Define major breakpoints
 *
 */

// Breakpoints
$breakpoints: (
    'sm': 480px,
    'md': 768px,
    'lg': 1024px,
    'xl': 1248px,
    'menu': 1024px
);

// Dump our breakpoints into some vars for easy reference in breakpoint mixins
$sm: map-get($breakpoints, sm);
$md: map-get($breakpoints, md);
$lg: map-get($breakpoints, lg);
$xl: map-get($breakpoints, xl);
$menu: map-get($breakpoints, menu);

/**
    e.g.
    .thing {
        background: green;

        @include breakpoint($md){
            background: blue;
        }
    }
*/
